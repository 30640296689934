.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

html {
  background-color: #F2F7FF;
}
table {
  width: 100%;
  background-color: #FFFFFF;
}
td, th {
  padding: 7px 0px 7px 15px;
}
th {
  color: #009CDE !important;
}
tr {
  border-bottom: 1px solid #DDDDDD;
}
thead {
  border-bottom: 2px solid #009CDE;
}
body {
  font-family: 'Poppins', sans-serif;
  color: #303030 !important;
}
a {
  color: #009CDE !important;
  text-decoration: underline;
}
button {
  font-weight: 500;
  padding: 10px 25px 15px 25px;
  font-size: 18px;
  border-radius: 5px;
  height: 50px;
}
.blueButton {
  background-color: #009CDE;
  border: 1px solid #009CDE !important;
  color: #FFFFFF;
}
.whiteButton {
  background-color: #FFFFFF;
  border: 1px solid #009CDE !important;
  color: #009CDE;
}
button:hover {
  cursor: pointer;
}
/*#region Common*/
.pageNumber {
  width: 45px !important;
  margin-left: 5px;
  margin-right: 5px;
  background-color: #FFFFFF;
}
.pageNumberDeiider {
  margin-top: 8px;
}
.pageNumber a {
  justify-content: center;
  text-decoration: none;
  display: flex;
  color: #303030 !important;
}
.pageHeaderTitle {
  font-size: 30px;
  font-weight: 900;
}
.statusLink a {
  text-decoration: none !important;
  color: #303030 !important;
}
.statusLink {
  border-bottom: 1px solid #DDDDDD;
}
.statusLink a {
  width: 20px;
}
.statusLink-active {
  border-bottom: 1px solid #009CDE;
}
.statusLink-active a {
  color: #009CDE !important;
}
.statusFiltering {
  display: inline-flex !important;
  background-color: #FFFFFF;
}
.statusFiltering a {
  width: 20px;
  text-decoration: none;
}
.statusFilteringItem-active a {
  background-color: #F2F7FF;
  padding: 5px 12px 5px 12px;
}
.contentContainer {
  background-color: #F2F7FF;
  margin-left: 22.1vw;
  margin-bottom: 20px;
}
.contentDivider {
  border-bottom: 1px solid #DDDDDD;
}
.formHeader {
  font-size: 24px;
  font-weight: 900;
}
.formGroup > label {
  position: relative;
  top: -10px;
  padding-left: 3px;
  z-index: 10;
  left: 21px;
  background-color: white;
  padding-right: 3px;
}
.formGroup > input, .formGroup > .css-2b097c-container {
  border: none !important;
  position: relative !important;
  box-shadow: none;
  top: -20px;
}
table > .css-2b097c-container {
  border: 10x solid black;
}
.wrapperClassName {
  border: 1px solid #DDDDDD;
  border-radius: 5px;
  min-height: 200px;
  padding: 10px;
}
.componentWrapper {
  background-color: white;
  padding: 30px;
}
.css-yk16xz-control {
  border: none !important;
}
.formGroup > input:focus {
  box-shadow: none;
}
.formGroup {
  position: relative;
  max-height: 50px;
  border: 1px solid #DDDDDD;
  background-color: white;
}
.checkboxFormGroup {
  position: relative;
  border: 1px solid #DDDDDD;
  background-color: white;
}
.checkboxFormGroup > label {
  position: relative;
  top: -10px;
  padding-left: 3px;
  z-index: 10;
  left: 21px;
  background-color: white;
  padding-right: 3px;
}
.checkboxFormGroup > input, .checkboxFormGroup > .css-2b097c-container {
  border: none !important;
  position: relative !important;
  box-shadow: none;
  top: -20px;
}
.checkbox input {
  opacity: 0;
  width: 1em;
  height: 1em;
}
.checkbox__control {
  display: inline-grid;
  width: 15px;
  height: 15px;
  border-radius: 5px;
  border: 1px solid #DDDDDD;
}
.checkbox__control svg {
  transition: transform 0.1s ease-in 25ms;
  transform: scale(0);
  transform-origin: bottom left;
  background-color: #009CDE;
}
.checkbox input:checked + .checkbox__control svg {
  transform: scale(1);
  background-color: #009CDE;
}
.input {
  margin-top: 1px;
  margin-bottom: 1px;
  font-weight: 400;
  border: 1px solid #DDDDDD !important;
  padding-left: 20px;
  padding-right: 15px;
}
.inputIcon {
  margin-left: -30px;
  cursor: pointer;
  position: absolute;
  margin-top: 10px;
}
tr:hover {
  cursor: pointer;
}
input[type=text] {
  border: 1px solid black;
}
.input::-webkit-input-placeholder {
  color: #303030;
  font-size: 16px;
}
.input:-moz-placeholder {
  color: #303030;
}
.input::-moz-placeholder {
  color: #303030;
}
.input:-ms-input-placeholder {
  color: #303030;
}
/*#endregion */
/*#region LanguageSwitcher*/
.css-26l3qy-menu {
  z-index: 30 !important;
}
/*#endregion */
/*#region Login*/
.logoContainer {
  width: 100vw;
  height: 20vh;
  background-color: #FFFFFF;
  clip-path: polygon(0% 100%, 0% 0%, 65% 0%);
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
}
.logoContainer img {
  position: absolute;
  z-index: 10;
  width: 23%;
  height: 39%;
  margin: 2% 0px 0px 2%;
}
.login {
  height: 100vh;
  background-color: #F2F7FF;
}
.loginButton {
  float: right;
}
.loginFormContainer {
  min-height: 300px;
  background-color: #FFFFFF;
  height: 40%;
  max-width: 570px;
  max-height: 500px;
  padding: 2% 2%;
}
.blueFooter {
  height: 5vh;
  background-color: #009CDE;
  clip-path: polygon(0 100%, 100% 0, 100% 100%);
  position: fixed;
  width: 100%;
  bottom: 0px;
  left: 0px;
  right: 0px;
}
.redFooter {
  height: 5vh;
  background-color: #E42313;
  clip-path: polygon(0% 100%, 0% 26%, 100% 0%);
  position: fixed;
  width: 100%;
  bottom: 0px;
  left: 0px;
  right: 0px;
}
/*#endregion */
/*#region Modal*/
.modal-card {
  width: 90%;
  min-height: 92%;
  max-height: 92%;
  max-width: 1500px;
  background-color: #FFFFFF;
  padding: 0px 25px 30px 25px;
  overflow: auto;
}
.modal-content {
  background-color: #fff;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 50%; 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  text-align: center;
  position: relative;
}
.modal-actions {
  display: flex;
  justify-content: space-between;
}
.modal {
  position: fixed; 
  z-index: 1000; 
  left: 0;
  top: 0;
  width: 100%; 
  height: 100%; 
  background-color: rgba(0, 0, 0, 0.4); 
  justify-content: center;
  align-items: center;
}
/*#endregion */
/*#region Navigation*/
.sidebar-menu {
  width: 22vw;
  background-color: #FFFFFF;
  height: 100vh;
  border: 1px solid #DDDDDD;
  overflow: overlay;
}
.sidebar-header {
  padding: 30px 20px 30px 20px;
}
.menu-list-container {
  margin: 35px 5px 0px 10px;
  padding: 0px 10px 0px 10px;
}
.menu-item-header {
  color: #009CDE;
  padding-left: 0.1vw;
  margin-bottom: 8px;
  font-size: 12px;
  font-weight: 900;
}
.navbar-item svg {
  height: 12px;
  margin-right: 0.2vw;
}
.navbar-item {
  text-decoration: none;
  padding-bottom: 1px !important;
  padding-top: 1px !important;
  color: #303030 !important;
}
.sidebar-menu-footer {
  position: fixed;
  bottom: 0px;
}
.imageConainer img {
  width: 40px;
  height: 40px;
}
html, body {
  height: 100%;
}
/*#endregion */
.public-DraftEditor-content {
  min-height: 250px;
}
.followUpQuestion {
  width: 200px;
}
.progressBar > div > div > span {
  display: none !important;
}
.css-14jk2my-container {
  bottom: 20px;
}
.followUpQuestion > div > div > .css-14jk2my-container {
  bottom: 0px !important;
}
.profileWrapper {
  padding: 20px;
  background-color: #F2F7FF;
}
.conditionWrapper .css-1hb7zxy-IndicatorsContainer {
  display: none;
}
.columnNames .css-g1d714-ValueContainer {
  background-color: #505050;
}
.css-g1d714-ValueContainer:focus {
  outline: none;
}
.numberOfMatchingProfiles {
  color: #009CDE;
}
.columnNames .css-1uccc91-singleValue {
  color: #FFFFFF;
}
.operators .css-g1d714-ValueContainer {
  background-color: #FFFFFF;
}
.operators .css-1uccc91-singleValue {
  color: #303030;
}
.polygonWrapper path {
  fill: #FFFFFF;
  stroke: #505050;
}
.polygonWrapper svg {
  background-color: #FFFFFF;
}
.columnNames .css-c8odyh-control {
  background-color: #505050;
}
.columnNames .field {
  background-color: #505050;
}
input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.borderBottomAndTopBlack {
  border-bottom: 1px solid #303030;
  border-top: 1px solid #303030;
}
.borderBottomAndTopGrey {
  border-bottom: 1px solid #DDDDDD;
  border-top: 1px solid #DDDDDD;
}
.borderBottomAndTopGrey input {
  border: 0px solid transparent !important;
}
.borderBottomAndTopGrey input:active {
  border: 0px solid transparent !important;
  box-shadow: none;
}
.borderBottomAndTopGrey input:focus {
  border: 0px solid transparent !important;
  box-shadow: none;
}
html, body {
  overscroll-behavior-y: none;
}
.questionContainer {
  margin-left: 3%;
  margin-right: 3%;
  padding: 60px;
  width: 45%;
  min-width: 500px;
  min-width: 700px;
  height: 100%;
  background-color: #FFFFFF;
}
.questionContainer p {
  margin: 16px;
}
.progressBarContainer {
  margin-left: 3%;
  margin-right: 3%;
  padding: 60px;
  width: 45%;
  min-width: 500px;
  min-width: 700px;
  background-color: #FFFFFF;
}
.questionHeader {
  font-size: 24px;
  font-weight: 700;
}
.sliderDiv {
  margin-top: 25px;
}
.rc-slider {
  height: 40px !important;
}
.rc-slider-mark {
  font-size: 14px;
}
.rc-slider-tooltip-inner {
  background-color: #009CDE !important;
  min-width: 35px !important;
  height: 40px !important;
  font-size: 24px !important;
  padding: 8px 4px !important;
}
.rc-slider-step {
  background-color: #009CDE;
}
.rc-slider-dot {
  width: 12px !important;
  height: 12px !important;
  bottom: -4px;
  border: 2px solid #009CDE !important;
  background-color: #009CDE !important;
}
.rc-slider-handle {
  height: 22px !important;
  width: 22px !important;
  margin-top: -9px !important;
  background-color: #E42313;
  border: 1px solid #E42313;
}
.css-2b097c-container {
  border: 1px solid #DDDDDD;
}
.progressBar {
  border-radius: 0px;
  width: 100%;
  background-color: #F2F7FF;
}
.padding-top-bottom-progressBar {
  padding-bottom: 6px;
  padding-top: 6px;
  background-color: #F2F7FF;
}
.padding-left-progressBar {
  padding-left: 10px;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}
.padding-right-progressBar {
  padding-right: 10px;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}
.border-right-progressBar {
  border-right: 4px solid #009CDE;
}
.form-control {
  padding-left: 6px !important;
  border-radius: 10px !important;
  border: #DDDDDD 1px solid !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  width: 100%;
}
.form-control:active {
  border: #DDDDDD 1px solid !important;
}
.form-control:focus {
  outline: none;
}
.custom-select {
  border-radius: 10px !important;
  border: #DDDDDD 1px solid !important;
}
.custom-select:focus {
  outline: none;
}
.pageHeader {
  position: sticky;
  top: 0;
  z-index: 32;
  background-color: #F2F7FF;
}
.addingInformationWrapper > div > div > .DraftEditor-root {
  height: 120px !important;
}
.react-datepicker__time-container--with-today-button {
  right: -85px !important;
}
.imageWrapper > img {
  width: 120px;
  height: 50px;
}
.surveyImageWrapper > div > img {
  height: 95px;
}
.surveyImageWrapper {
  margin-left: 5px;
  margin-right: 5px;
}
.tooltip-relative {
  position: relative;
}
.tooltip:hover .tooltiptext {
  visibility: visible;
  color: #303030;
  background-color: #F2F7FF !important;
  border: 2px solid #009CDE;
}
.tooltip .tooltiptext {
  visibility: hidden;
  width: 280px;
  background-color: none;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0px;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: 100%;
  left: -35%;
  margin-left: -60px;
}

.deletetooltip:hover .deletetooltiptext {
  visibility: visible;
  color: #303030;
  background-color: #F2F7FF !important;
  border: 2px solid #009CDE;
}
.deletetooltip .deletetooltiptext {
  visibility: hidden;
  width: 280px;
  background-color: none;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0px;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: 100%;
  left: -100%;
  margin-left: -200px;
}

.questionFormHeader {
  position: sticky;
  top: 0;
  z-index: 32;
  background-color: #FFFFFF;
}
.errorMessage {
  color: #E42313;
}
.profileImage > img {
  width: 220px;
  height: 80px;
}
.profileImage {
  clip-path: polygon(0% 100%, 0% 0%, 65% 0%);
  width: 50% !important;
  height: 180px;
  background-color: #FFFFFF;
  margin-left: 10px !important;
  margin-top: 10px !important;
}
@media only screen and (max-width: 1023px) {
  body {
    overflow-x: hidden;
  }
  .forgotPassword {
    text-align: center;
  }
  .loginButton {
    float: none;
  }
  .loginButtonContainer {
    text-align: center;
  }
  .surveyImagesLeft {
    display: none;
  }
  .progressBarColumns {
    display: inline-flex;
    max-width: 100% !important;
    flex: 1;
    margin-right: 20px !important;
  }
  .questionContainer {
    min-width: 100% !important;
    padding: 30px !important;
  }
  .progressBarContainer {
    min-width: 100% !important;
    padding: 10px !important;
    display: flex;
    justify-content: center;
  }
  .questionHeader {
    font-size: 18px !important;
  }
  .profileImage {
    width: 100% !important;
    clip-path: none !important;
    -webkit-clip-path: none !important;
    height: 125px !important;
  }
  .countrySelect {
    width: auto !important;
  }
  .nextButtonColumn {
    position: fixed;
    bottom: 0px;
    background-color: white;
    -webkit-box-pack: flex-end !important;
    -ms-flex-pack: flex-end !important;
    justify-content: flex-end !important;
    z-index: 1000 !important;
    width: 100%;
    left: 0px;
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .css-1pv45zp-container {
    width: auto !important;
  }
  .label {
    display: inline !important;
  }
  .bottomSurveyImages {
    display: block !important;
  }
  .sliderDiv {
    width: 100%;
    background-color: #F2F7FF;
  }
  .sliderDiv .field-body {
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 25px;
    height: 75px;
  }
  .rc-slider-dot {
    display: none;
  }
  .rc-slider-dot:first-child {
    display: block;
  }
  .rc-slider-dot:last-child {
    display: block;
  }
  .rc-slider-mark-text {
    display: none;
  }
  .rc-slider-mark-text:first-child {
    display: block;
  }
  .rc-slider-mark-text:last-child {
    display: block;
  }
  .rc-slider-handle {
    pointer-events: none;
  }
  .questionContainer {
    padding: 0px !important;
    margin-left: 0px !important;
  }
  .questionHeader {
    padding: 20px !important;
    margin-left: 5% !important;
    padding-bottom: 0px !important;
    padding-right: 30px !important;
  }
  .addingInformation {
    padding: 20px !important;
    margin-left: 5% !important;
    padding-bottom: 20px !important;
  }
  .profileImageWrapper {
    margin-bottom: 25px !important;
  }
  .progressBarContainerContainer {
    margin-bottom: 25px !important;
  }
  .swipeIconContainer {
    display: flex !important;
    justify-content: center;
    padding-top: 15px;
  }
  #root {
    padding-bottom: 50px;
  }
  .informationContainer {
    padding: 30px !important;
  }
  .paddedAnswerContainer {
    padding-left: 50px !important;
    padding-bottom: 50px !important;
    padding-right: 50px !important;
  }
  .css-26l3qy-menu {
    z-index: 9999999 !important;
    overscroll-behavior-y: none;
  }
  .surveyNumber {
    word-break: break-word;
  }
  .progressbarItem {
    justify-content: unset !important;
  }
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(53, 52, 52, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
}
